import { useState, useMemo, useEffect, useRef } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TablePagination as MuiTablePagination,
  Paper,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { getComparator, stableSort } from "utils/collapseTable";
import styled from "styled-components/macro";

import { ORDER_DIRECTION } from "constants/index";
import CollapseTableHeader from "./CollapseTableHeader";
import CollapseTableRow from "./CollapseTableRow";
import CollapseTableRowSkeleton from "./CollapseTableRowSkeleton";

const ROW_PER_PAGE_OPTIONS = [5, 10, 25, 50];

const getDefaultTableConfig = (tableConfig) => ({
  collapse: false,
  forceViewChild: false,
  isSmall: false,
  title: null,
  numberRowsPerPage:
    tableConfig && tableConfig.rowsPerPageOptions
      ? tableConfig.rowsPerPageOptions[0]
      : ROW_PER_PAGE_OPTIONS[0],
  rowsPerPageOptions: ROW_PER_PAGE_OPTIONS,
  backgroundColor: "#fff",
  order: "",
  orderBy: "",
  actions: null,
  pagination: null,
});

const TablePagination = styled(MuiTablePagination)`
  width: 100%;
`;

const Title = styled(Typography)`
  padding: ${(props) => props.theme.spacing(2)}px;
  margin: 0;
  background-color: ${(props) => props.theme.sidebar.background};
  color: ${(props) => props.theme.sidebar.color};
  width: 100%;
  text-transform: uppercase;
`;

const CollapseTable = ({
  header,
  data,
  parentData,
  isLoading = false,
  tableConfig: tableConfigParameter,
  children,
}) => {
  const tableConfig = {
    ...getDefaultTableConfig(tableConfigParameter),
    ...tableConfigParameter,
  };

  const {
    collapse,
    forceViewChild,
    isSmall,
    title,
    numberRowsPerPage,
    rowsPerPageOptions,
    backgroundColor,
    order: customOrder,
    orderBy: customOrderBy,
    actions,
    pagination,
  } = tableConfig;

  const [order, setOrder] = useState(customOrder || ORDER_DIRECTION.ASC);
  const [orderBy, setOrderBy] = useState(customOrderBy || header[0].id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(numberRowsPerPage);
  const loadedData = useRef([]);

  const observableState = useMemo(
    () => ({ order, orderBy, page, rowsPerPage }),
    [order, orderBy, page, rowsPerPage]
  );

  const calculatedMaxPage = useMemo(
    () => Math.ceil(data.length / rowsPerPage) - 1,
    [data, rowsPerPage]
  );

  const dataIsNotUpdated =
    data.length > 0 && data.length !== loadedData.current.length;

  const tablePaginationIsWrong =
    dataIsNotUpdated &&
    data.length < loadedData.current.length &&
    page > calculatedMaxPage
      ? true
      : false;

  useEffect(() => {
    if (dataIsNotUpdated) {
      loadedData.current = data;
    }
  }, [dataIsNotUpdated, data]);

  useEffect(() => {
    if (tablePaginationIsWrong) {
      setPage(calculatedMaxPage);
    }
  }, [tablePaginationIsWrong, calculatedMaxPage]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === ORDER_DIRECTION.ASC;
    setOrder(isAsc ? ORDER_DIRECTION.DESC : ORDER_DIRECTION.ASC);
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowStyle = {
    backgroundColor,
  };

  const headerProps = {
    order,
    orderBy,
    onRequestSort: handleRequestSort,
    data: header,
    ...(parentData && { parentData }),
    collapse,
    rowStyle,
    actions,
  };

  const rowProps = {
    header,
    actions,
    collapse,
    forceViewChild,
    backgroundColor,
    rowStyle,
    observableState,
    parentData,
  };

  const skeletonProps = {
    rows: numberRowsPerPage,
    cols: header.length + (collapse ? 1 : 0) + (actions ? 1 : 0),
  };

  const paginationProps = pagination
    ? {
        rowsPerPageOptions: pagination.rowsPerPageOptions,
        count: pagination.totalRows,
        rowsPerPage: pagination.rowsPerPage,
        page: pagination.page,
        onChangePage: pagination.handleChangePage,
        onChangeRowsPerPage: pagination.handleChangeRowsPerPage,
      }
    : {
        rowsPerPageOptions: rowsPerPageOptions,
        count: data.length,
        rowsPerPage: rowsPerPage,
        page: page > 0 && data.length === rowsPerPage ? 0 : page,
        onChangePage: handleChangePage,
        onChangeRowsPerPage: handleChangeRowsPerPage,
      };

  return (
    <Grid container component={Paper} elevation={3}>
      {title && (
        <Title variant="h4" gutterBottom display="inline">
          {title}
        </Title>
      )}
      <TableContainer>
        <Table size={isSmall ? "small" : "medium"}>
          <CollapseTableHeader {...headerProps} />
          <TableBody>
            {isLoading ? (
              <CollapseTableRowSkeleton {...skeletonProps} />
            ) : (
              stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) =>
                  collapse && children ? (
                    <CollapseTableRow key={index} data={row} {...rowProps}>
                      {collapse && children}
                    </CollapseTableRow>
                  ) : (
                    <CollapseTableRow key={index} data={row} {...rowProps} />
                  )
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length > 0 && !tablePaginationIsWrong && (
        <TablePagination
          style={rowStyle}
          component="div"
          {...paginationProps}
        />
      )}
    </Grid>
  );
};

CollapseTable.propTypes = {
  header: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  parentData: PropTypes.object,
  isLoading: PropTypes.bool,
  tableConfig: PropTypes.shape({
    title: PropTypes.string,
    collapse: PropTypes.bool,
    forceViewChild: PropTypes.bool,
    isSmall: PropTypes.bool,
    numberRowsPerPage: PropTypes.number,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    backgroundColor: PropTypes.string,
    order: PropTypes.oneOf(Object.values(ORDER_DIRECTION)),
    orderBy: PropTypes.string,
    actions: PropTypes.shape({
      add: PropTypes.func,
      edit: PropTypes.func,
      remove: PropTypes.func,
      other: PropTypes.func,
    }),
    pagination: PropTypes.shape({
      page: PropTypes.number.isRequired,
      rowsPerPage: PropTypes.number.isRequired,
      totalRows: PropTypes.number.isRequired,
      rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
      handleChangePage: PropTypes.func.isRequired,
      handleChangeRowsPerPage: PropTypes.func.isRequired,
    }),
  }),
  children: PropTypes.func,
};

export default CollapseTable;

import PropTypes from "prop-types";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { Plus as AddIcon } from "react-feather";

const ActionButton = styled(IconButton)`
  padding: ${(props) => props.theme.spacing(2)}px;
  margin: 0 ${(props) => props.theme.spacing(3)}px;
`;

const CollapseTableHeader = ({
  order,
  orderBy,
  onRequestSort,
  data,
  parentData,
  actions,
  collapse,
  rowStyle,
}) => (
  <TableHead>
    <TableRow style={rowStyle}>
      {collapse && <TableCell />}
      {data.map(({ id, label, align = "left" }) => (
        <TableCell
          key={id}
          align={align}
          sortDirection={orderBy === id ? order : false}
        >
          <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : "asc"}
            onClick={(e) => onRequestSort(id)}
          >
            {label}
          </TableSortLabel>
        </TableCell>
      ))}

      {actions &&
        (actions.add ? (
          <TableCell align="right" padding="none">
            <Tooltip title="Add" placement="top">
              <ActionButton
                color="primary"
                onClick={() => actions.add(parentData)}
              >
                <AddIcon />
              </ActionButton>
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell />
        ))}
    </TableRow>
  </TableHead>
);

CollapseTableHeader.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  parentData: PropTypes.object,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  collapse: PropTypes.bool,
  rowStyle: PropTypes.object,
  actions: PropTypes.object,
};

export default CollapseTableHeader;

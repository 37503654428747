import { format, utcToZonedTime } from "date-fns-tz";
import { parseISO } from "date-fns";

export const isNumber = (value) => {
  if (isNaN(value)) {
    return false;
  } else {
    return true;
  }
};

export const getBooleanValue = (value) => {
  if (typeof value === "boolean") return value;

  if (typeof value === "string") return value.toLowerCase() === "true";

  return false;
};

export const formatNumberValue = (value, isPercentage = false) => {
  return value === 0 && isPercentage
    ? `${value}%`
    : value && isPercentage
    ? `${parseFloat(Number(value).toFixed(1)).toLocaleString("en-US")}%`
    : value === 0
    ? 0
    : value
    ? parseFloat(Number(value).toFixed(1)).toLocaleString("en-US")
    : "-";
};

export const isPromise = (func) => {
  if (typeof func === "object" && typeof func.then === "function") {
    return true;
  }

  return false;
};

export const formatUTCDate = (date, formatter) => {
  return format(utcToZonedTime(date, "UTC"), formatter, {
    timeZone: "UTC",
  });
};

export const UTCToLocalDate = (date, formatter) => {
  const localDate = parseISO(date);
  localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
  return format(localDate, formatter);
};
